import React from 'react';
import { useList, useToggle, useCounter, useAsync, } from 'react-use';
import { fromPairs, keyBy, } from 'lodash';
import { startOfMonth, startOfDay, endOfDay, addDays, } from 'date-fns';
import { collection, query, where, orderBy as dbOrderBy, limit, getCountFromServer, getAggregateFromServer, sum as dbSum, count as dbCount, } from 'firebase/firestore';
import numeral from 'numeral';

import firebase, { functions } from '../../firebase';
import Page from '../hocs/Page';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import RichTextContent from '../RichTextContent';
import off20241221Image from '../../images/banners/off_20241221.png';
import chatworkImage from '../../images/banners/chatwork.png';
import hanroplusImage from '../../images/banners/hanroplus.jpg';
import buzzphotoImage from '../../images/banners/buzzphoto.jpg';

const today = startOfDay(new Date());
const { keys, entries } = Object;
const db = firebase.firestore();
const dateRanges = {
  thisDate: { label: '当日', start: today, end: null, },
  prevDate: { label: '前日', start: addDays(today, -1), end: addDays(today, -1), },
  thisMonth: { label: '当月', start: startOfMonth(today), end: null, },
  all: { label: '全期間', start: null, end: null, },
};
const metrics = {
  totalUserAmount: { label: '売上', type: 'yen', },
  totalUserProfit: { label: '利益', type: 'yen', },
  count: { label: '注文数', },
};

export default Page(function Dashboard (props) {
  const { tenant } = props;
  const generalSetting = useDocumentSubscription(db.collection('settings').doc('general'));
  const { value: data, } = useAsync(async () => {
    const data = fromPairs(await Promise.all(entries(dateRanges).map(async ([dateRangeName, { start, end }]) => {
      const ref = query(collection(db, 'orders'), where('tenantId', '==', tenant.id), dbOrderBy('createdAt'), ...[(start && where('createdAt', '>=', startOfDay(start))), (end && where('createdAt', '<=', endOfDay(end)))].filter(_ => _), dbOrderBy('totalUserAmount'), dbOrderBy('totalUserProfit'));
      // await getCollectionData(query(ref, where('totalUserAmount'
      return [dateRangeName, {
        // ...(await getCountFromServer(ref)).data(),
        ...(await getAggregateFromServer(ref, {
          count: dbCount(),
          totalUserAmount: dbSum('totalUserAmount'),
          totalUserProfit: dbSum('totalUserProfit'),
        })).data(),
      }];
    })));
    return data;
  }, []);
  const counts = null;

  return (
    <div className="dashboard container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-start mb-3">
          <h4>ダッシュボード</h4>
        </div>
        <div>
          <table className="table table-bordered">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th style={{ minWidth: 200 }}></th>
                {
                  Object.values(dateRanges).map(({ label }) => {
                    return (
                      <th key={label} style={{ minWidth: 200 }}>
                        {label}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                entries(metrics).map(([metric, { label, type }]) => {
                  return (
                    <tr key={metric}>
                      <th className="text-center">
                        {label}
                      </th>
                      {
                        entries(data || {}).map(([dateRangeName, values]) => {
                          return (
                            <td key={dateRangeName} className="text-center large font-weight-bold">
                              {type === 'yen' && <span>&yen;</span>}{numeral(values[metric]).format()}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          <RichTextContent html={generalSetting?.dashboardContent || ''} />
        </div>
        <div className="mt-5">
          <div className="d-flex justify-content-start align-items-center gap-2">
            <a className="col-4 d-flex p-0" href="https://www.chatwork.com/g/hh2696km24u424" target="blank">
              <img className="col-12 p-0" src={chatworkImage} />
            </a>
            <a className="col-4 d-flex p-0" href="https://www.notion.so/wizzlinx/45ccdc1538d344cc95949f5bc2991080?pvs=4" target="blank">
              <img className="col-12 p-0" src={hanroplusImage} />
            </a>
            <a className="col-4 d-flex p-0" href="https://buzzphoto.jp/contact/" target="blank">
              <img className="col-12 p-0" src={buzzphotoImage} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
