import React, { memo, Fragment, useState, useMemo, useRef, useEffect, } from 'react';
import { fromPairs, last, mergeWith, sortBy, mapValues, findKey, merge, intersection, isString, groupBy, uniqBy, chunk, uniq, isEqual, omit, isEmpty, pick, countBy, pickBy, get, orderBy, keyBy, difference, } from 'lodash';
import { collection, query, where, orderBy as dbOrderBy, limit, getCountFromServer, } from 'firebase/firestore';
import { Button, Input, FormGroup, Label, } from 'reactstrap';
import { useList, useToggle, useCounter, useAsync, } from 'react-use';
import { format as formatDate, addMonths, addHours, startOfDay, endOfDay, addYears, } from 'date-fns';
import { toast } from 'react-toastify';
import Select from 'react-select';
import classnames from 'classnames';
import numeral from 'numeral';
import qs from 'qs';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { serial, } from '../../shared/util';
import { getDocumentData, getCollectionData, getAllCollectionDataByChunk, batch } from '../../shared/firebase';
import { yen, highlightText, } from '../../util';
import { productStatuses, } from '../../shared/config';
import { fieldsForAdminImport, computeIdInMall, generateProductStatusChunkMaps, adminFields as productAdminFields, } from '../../shared/models/product';
import { pricePerVariationStatus, } from '../../shared/models/parentVariation';
import { variationDimensions, } from '../../shared/models/variation';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useReadMore from '../hooks/useReadMore';
import DateSelector from '../DateSelector';
import ModelFormModal from '../modals/ModelFormModal';
import ExpanderPage from '../hocs/ExpanderPage';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import QueryBoolean from '../QueryBoolean';

const blankObject = {};
const blankArray = [];
const { round } = Math;
const fetchParentVariation = functions.httpsCallable('fetchParentVariation');
const db = firebase.firestore();
const variationsRef = db.collection('variations');
const productsRef = db.collectionGroup('products');
const parentVariationsRef = db.collectionGroup('parentVariations');
const tenantsRef = db.collection('tenants');
const { keys, entries, values, } = Object;
const countPerPageOptions = [100, 300, 500].map(_ => _.toString()).map(_ => ({ value: _, label: _ }));
const statusOptions = entries(productStatuses).map(([k, v]) => ({ value: k, label: v.label }));
const errorOptions = [{ value: 'error', label: 'エラーあり' }, { value: 'notError', label: 'エラーなし' }];
const sellersCountTypeOptions = [{ value: '1', label: '1' }, { value: '2', label: '2', }, { value: 'some', label: '3以上' }, { value: 'none', label: 'なし' }];
const ngWordTypeOptions = [{ value: 'has', label: '有' }, { value: 'doesntHave', label: '無' }];
const searchTypes = {
  productId: { label: '商品ID', type: 'db', dbQuery: (collectionRef, keyword) => collectionRef.where('id', '==', keyword), filter: (items, keyword) => items.filter(_ => _.id === keyword), },
  sku: { label: 'SKU', type: 'db', dbQuery: (collectionRef, keyword) => collectionRef.where('sku', '==', keyword), filter: (items, keyword) => items.filter(_ => _.sku === keyword), },
  title: { label: '商品名(前方一致検索)(長めに入力推奨)', type: 'db', dbQuery: (collectionRef, keyword) => collectionRef.where('amazonProduct.AttributeSets.ItemAttributes.Title', '>=', keyword).where('amazonProduct.AttributeSets.ItemAttributes.Title', '<=', keyword + '\uf8ff'), filter: (items, keyword, ids) => items.filter(_ => ids.includes(_.id)), },
};
const searchTypeOptions = entries(searchTypes).map(([k, _]) => ({ label: _.label, value: k }));
const getVariationParentAsin = (product) => get(product?.amazonProduct, 'Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN');
let prevLastRef = null;

export default ExpanderPage(function ExpanderProducts (props) {
  const { user, expander, history, location, location: { search } } = props;
  const [selectedItemIds, { set: setSelectedItemIds, push: selectItemId, removeAt: removeSelectedItemIdAt }] = useList([]);
  const [screenVersion, { inc: updateScreenVersion }] = useCounter(0);
  const [countsVersion, { inc: updateCountsVersion }] = useCounter(0);
  const selectedItemIdsRef = useRef(selectedItemIds);
  const queryParams = qs.parse(decodeURI(search.slice(1)));
  const {
    shopId,
    searchType,
    keyword,
    exhibitedAtStartOn: exhibitedAtStartOnString,
    exhibitedAtEndOn: exhibitedAtEndOnString,
    countPerPage = '100',
  } = queryParams;
  const { count: displayCount, readMore } = useReadMore({ countPerPage: Number(countPerPage), });
  const exhibitedAtStartOn = exhibitedAtStartOnString && new Date(exhibitedAtStartOnString);
  const exhibitedAtEndOn = exhibitedAtEndOnString && new Date(exhibitedAtEndOnString);
  const isSearching = !isEmpty(searchType);
  const unselectItemId = _ => removeSelectedItemIdAt(selectedItemIdsRef.current.indexOf(_));
  const isSelecting = selectedItemIds.length > 0;
  const shops = useCollectionSubscription(expander.ref.collection('shops').orderBy('createdAt'), [expander]);
  const shopsById = keyBy(shops, 'id');
  const shop = shopsById[shopId];
  const shopOptions = shops.map(_ => ({ label: _.name, value: _.id }));
  const { items: tenants, isLoading: isLoadingTenants } = useCollectionFetch(tenantsRef, [], { detail: true });
  const filteredTenants = useMemo(() => tenants.filter(_ => _.salesDirect == (expander.isDirect ?? false)), [tenants, expander]);
  const filteredTenantIds = useMemo(() => filteredTenants.map(_ => _.id), [filteredTenants]);
  const tenantsById = keyBy(tenants, 'id');
  const tenantOptions = filteredTenants.map(_ => ({ label: _.name, value: _.id }));
  const dbSearchingProducts = useCollectionFetch(searchTypes[searchType]?.type === 'db' && keyword && searchTypes[searchType].dbQuery(db.collectionGroup('products'), keyword), [searchType, keyword], { disablesReset: false });
  const dbSearchingProductIds = useMemo(_ => dbSearchingProducts.map(_ => _.id), [dbSearchingProducts]);

  // TODO: 出品日絞り込み,降順
  const filteredProductShopStatusesRef = useMemo(() => {
    if(shopId == null) return;

    let filteredProductShopStatusesRef = db.collection('productShopStatuses').where('shopId', '==', shopId);
    if(isSearching) {
      filteredProductShopStatusesRef = filteredProductShopStatusesRef.where('productId', 'in', (_ => isEmpty(_) ? ['dummy'] : _)(dbSearchingProductIds.slice(0, 10)));
    } else {
      filteredProductShopStatusesRef = filteredProductShopStatusesRef.orderBy('updatedAt', 'desc');
      if(queryParams.tenantId) {
        filteredProductShopStatusesRef = filteredProductShopStatusesRef.where('tenantId', '==', queryParams.tenantId);
      }
      if(queryParams.status) {
        filteredProductShopStatusesRef = filteredProductShopStatusesRef.where('status', '==', queryParams.status);
      }
    }
    return filteredProductShopStatusesRef;
  }, [shopId, isSearching, dbSearchingProductIds, queryParams.tenantId, queryParams.status]);
  const productShopStatuses = useCollectionFetch(shopId && filteredProductShopStatusesRef?.limit(displayCount), [shopId, filteredProductShopStatusesRef, displayCount, screenVersion], { disablesChunk: true });
  const productShopStatusesByProductId = useMemo(_ => keyBy(productShopStatuses, 'productId'), [productShopStatuses]);
  const { value: productShopStatusCounts, } = useAsync(async () => {
    if(!shopId) return;

    const counts = fromPairs(await Promise.all(keys(productStatuses).map(async (status) => {
      return [status, (await getCountFromServer(query(collection(db, 'productShopStatuses'), where('shopId', '==', shopId), where('status', '==', status)))).data()];
    })));
    return counts;
  }, [shopId, countsVersion]);
  const { value: registerItemJobCounts, } = useAsync(async () => {
    if(!shopId) return;

    const counts = fromPairs(await Promise.all(Array(4).fill().map(async (_, i) => {
      return [i, (await getCountFromServer(query(collection(db, 'registerItemJobs'), where('shopId', '==', shopId), where('count', '==', i), where('status', '==', 'initial')))).data()];
    })));
    return counts;
  }, [shopId, countsVersion]);

  const { items: baseProducts, isLoading: isLoadingBaseProducts } = useDocumentsFetch(productShopStatuses.map(_ => db.collection('tenants').doc(_.tenantId).collection('products').doc(_.productId)), [productShopStatuses], { detail: true });
  const { items: parentVariations, isLoading: isLoadingParentVariations } = useDocumentsFetch(baseProducts.filter(getVariationParentAsin).map(_ => _.ref.parent.parent.collection('parentVariations').doc(getVariationParentAsin(_))), [baseProducts], { detail: true });
  const parentVariationsById = keyBy(parentVariations, _ => [_.ref.parent.parent.id, _.id].join('--'));
  const { items: variations, isLoading: isLoadingVriations } = useDocumentsFetch(uniqBy(baseProducts, getVariationParentAsin).filter(getVariationParentAsin).map(_ => variationsRef.doc(getVariationParentAsin(_))), [baseProducts], { detail: true, });
  const variationsById = keyBy(variations, 'id');
  const childProductsRefs = uniqBy(
    baseProducts
      .map(_ => [_, _.ref.parent.parent.id, _.amazonProduct?.Relationships?.VariationParent?.Identifiers?.MarketplaceASIN?.ASIN])
      .filter(_ => _[2]),
    _ => _.slice(1).join('-')
  ).map(_ => _[0].ref.parent.parent.collection('products').where('amazonProduct.Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN', '==', _[2]));
  const { items: childProducts, isLoading: isLoadingChildProducts } = useCollectionsFetch(childProductsRefs, [baseProducts], { detail: true, });
  const { items: childProductShopStatuses, isLoading: isLoadingChildProductShopStatuses } = useCollectionsFetch(childProducts.map(_ => db.collection('productShopStatuses').where('shopId', '==', shopId).where('productId', '==', _.id)), [childProducts], { detail: true });
  const childProductShopStatusesByProductId = useMemo(_ => keyBy(childProductShopStatuses, 'productId'), [childProductShopStatuses]);
  const allProductShopStatusesByProductId = useMemo(_ => ({ ...productShopStatusesByProductId, ...childProductShopStatusesByProductId }), [productShopStatusesByProductId, childProductShopStatusesByProductId]);

  // NOTE: filter
  const filterChildProducts = (childProducts) => {
    let filteredChildProducts = childProducts;
    if(isSearching) {
      if(!isEmpty(keyword)) {
        const { filter, } = searchTypes[searchType];
        filteredChildProducts = filter(filteredChildProducts, keyword, dbSearchingProductIds);
      } else {
      }
    } else {
      if(!isEmpty(queryParams.status)) {
        const status = queryParams.status;
        filteredChildProducts = filteredChildProducts.filter(_ => childProductShopStatusesByProductId[_.id]?.status === queryParams.status);
        /*
        if(!isEmpty(exhibitedAtStartOnString)) {
          filteredChildProducts = filteredChildProducts.filter(_ => productChunkData[_.id]?.[shopId]?.exhibitedAt?.toDate() >= startOfDay(exhibitedAtStartOn));
        }
        if(!isEmpty(exhibitedAtEndOnString)) {
          filteredChildProducts = filteredChildProducts.filter(_ => productChunkData[_.id]?.[shopId]?.exhibitedAt?.toDate() <= endOfDay(exhibitedAtEndOn));
        }
        */
      }
    }
    return filteredChildProducts;
  };
  const filteredChildProducts = useMemo(() => {
    return filterChildProducts(childProducts);
  }, [childProducts, childProductShopStatusesByProductId]);

  const products = uniqBy([...baseProducts, ...filteredChildProducts].filter(_ => _?.updatedAt != null), 'id');
  const productsById = keyBy(products, 'id');

  const generateRowItems = (baseProducts, tenants, filteredChildProducts, parentVariationsById, allProductShopStatusesByProductId) => {
    const childProductsGroupedByParentAsin = omit(groupBy(filteredChildProducts, getVariationParentAsin), ['undefined']);
    return uniqBy(baseProducts, _ => getVariationParentAsin(_) || _.id).map((product) => {
      const variationParentAsin = getVariationParentAsin(product);
      const childProducts = childProductsGroupedByParentAsin[variationParentAsin];
      const sameParentAsinGroup = (childProducts || [product]);
      const items = sameParentAsinGroup.map((product) => {
        const tenant = tenantsById[product.ref.parent.parent.id];
        const parentVariation = parentVariationsById[[tenant?.id, variationParentAsin].join('--')];
        const idInMall = computeIdInMall(product, tenant, parentVariation);
        const productShopStatus = allProductShopStatusesByProductId[product.id];
        return {
          ...product,
          childProducts,
          tenant,
          parentVariation,
          idInMall,
          sameParentAsinGroup,
          productShopStatus,
        };
      });
      return items.map((item) => {
        const sameIdInMallGroup = items.filter(_ => _.idInMall === item.idInMall);
        return {
          ...item,
          sameIdInMallGroup,
        };
      });
    });
  };
  const rowItems = useMemo(() => {
    if([isLoadingBaseProducts, isLoadingTenants, isLoadingChildProducts, isLoadingChildProductShopStatuses, isLoadingParentVariations].some(_ => _)) return blankArray;

    return generateRowItems(baseProducts, tenants, filteredChildProducts, parentVariationsById, allProductShopStatusesByProductId);
  }, [isLoadingBaseProducts, isLoadingTenants, isLoadingChildProducts, isLoadingChildProductShopStatuses, queryParams.tenantId, isLoadingParentVariations, allProductShopStatusesByProductId]);

  // NOTE: filter
  let filteredRowItems = rowItems;
  if(!isEmpty(queryParams.ngWordType)) {
    filteredRowItems = filteredRowItems.map((items) => items.filter((rowItem) => {
      const { ngWords } = rowItem;
      return ({
        has: !isEmpty(ngWords),
        doesntHave: isEmpty(ngWords),
      })[queryParams.ngWordType];
    })).filter(_ => _.length > 0);
  }
  const selectedItems = filteredRowItems.flat().filter(_ => selectedItemIds.includes(_.id));
  const uniqParentVariationSelectedItems = uniqBy(selectedItems, _ => _.idInMall);
  const selectedSameIdInMallItems = uniqBy(selectedItems.flatMap(_ => _.sameIdInMallGroup), 'id');
  const [updatesStatusForAll, setUpdatesStatusForAll] = useToggle(false);

  const [progress, setProgress] = useState(null);

  const allRowsForExport = async () => {
    setProgress(0);
    const allProductShopStatuses = await getAllCollectionDataByChunk(filteredProductShopStatusesRef);
    const allProductShopStatusesByProductId = keyBy(allProductShopStatuses, 'productId');
    let baseProducts = [];
    let parentVariations = [];
    let variations = [];
    await serial(chunk(allProductShopStatuses, 100), async (productShopStatuses, i) => {
      await Promise.all([
        await (async () => {
          setProgress(i * 100 / allProductShopStatuses.length);
          const chunkBaseProducts = (await Promise.all(productShopStatuses.map(_ => getDocumentData(db.collection('tenants').doc(_.tenantId).collection('products').doc(_.productId))))).filter(_ => _);
          const chunkParentVariations = (await Promise.all(uniqBy(chunkBaseProducts, getVariationParentAsin).filter(getVariationParentAsin).map(_ => getDocumentData(_.ref.parent.parent.collection('parentVariations').doc(getVariationParentAsin(_)))))).filter(_ => _);
          const chunkVariations = (await Promise.all(uniqBy(chunkBaseProducts, getVariationParentAsin).filter(getVariationParentAsin).map(_ => getDocumentData(variationsRef.doc(getVariationParentAsin(_)))))).filter(_ => _);
          baseProducts = [...baseProducts, ...chunkBaseProducts];
          parentVariations = [...parentVariations, ...chunkParentVariations];
          variations = [...variations, ...chunkVariations];
        })(),
        await new Promise(_ => setTimeout(_, 2000)),
      ]);
    }, Promise.resolve());
    const parentVariationsById = keyBy(parentVariations, _ => [_.ref.parent.parent.id, _.id].join('--'));
    const variationsById = keyBy(variations, 'id');
    const items = generateRowItems(baseProducts, tenants, baseProducts, parentVariationsById, allProductShopStatusesByProductId).flat();
    setProgress(null);
    return rowsForExport(items, variationsById);
  };
  const allRowsForExportForExhibit = async () => {
    const allProductShopStatuses = await getAllCollectionDataByChunk(filteredProductShopStatusesRef);
    return allProductShopStatuses.map((productShopStatus) => {
      const { tenantId, productId, exhibitedAt, status, title, ngWords, errorMessage, } = productShopStatus;
      return {
        tenantId,
        id: productId,
        status,
        exhibitedAt: exhibitedAt && formatDate(exhibitedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        createsRegisterItemJob: 'FALSE',
        createsUpdateItemJob: 'FALSE',
        title,
        ngWords,
        errorMessage,
      };
    });
  };
  const rowsForExport = (rowItems, variationsById) => rowItems.map((row) => {
    const { id, sku, ref, updatedAt, createdAt, netPrice, amazonProduct, amazonProductEvaluationData, quantity, inventorySyncFailedAt, inventorySyncErrorMessages, ngWords = [], productShopStatus, } = row;
    const { browseClassification } = amazonProduct?.catalogSummaries?.[0] || {};
    const tenant = tenantsById[ref.parent.parent.id];
    const title = get(amazonProduct, 'AttributeSets.ItemAttributes.Title');
    const imageUrl = get(amazonProduct, 'AttributeSets.ItemAttributes.SmallImage.URL');
    const asin = get(amazonProduct, 'Identifiers.MarketplaceASIN.ASIN');
    const amount = get(amazonProduct, 'Offers.Offer.BuyingPrice.ListingPrice.Amount');
    const variationParentAsin = getVariationParentAsin(row);
    const parentVariation = parentVariationsById[[tenant.id, variationParentAsin].join('--')];
    const variation = variationsById[variationParentAsin];
    const variationChild = get(variation, 'variationChildren', []).find(_ => get(_, 'Identifiers.MarketplaceASIN.ASIN') === asin);
    const isVariationsProduct = variationParentAsin && !get(parentVariation, 'pricePerVariationEnabled');
    const url = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;

    return {
      tenantId: tenant && tenant.id,
      tenantName: tenant && tenant.name,
      sellersCount: get(amazonProductEvaluationData, 'sellersCount', null),
      starValue: get(amazonProductEvaluationData, 'starValue', null),
      reviewsCount: get(amazonProductEvaluationData, 'reviewsCount', null),
      amazonCategoryName: browseClassification?.displayName,
      amazonCategoryId: browseClassification?.classificationId,
      variationParentAsin,
      asin,
      sku,
      id,
      rakutenId: isVariationsProduct ? `${tenant.keyForMall}--${variationParentAsin.toLowerCase()}` : id,
      url,
      imageUrl,
      title,
      amount,
      inventorySupply: quantity,
      netPrice,
      salePrice: netPrice * 1.3,
      updatedAt: updatedAt && formatDate(updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
      updatedByExpanderAt: productShopStatus?.updatedAt && formatDate(productShopStatus.updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
      createdAt: createdAt && formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
      status: productShopStatus?.status,
      exhibitedAt: productShopStatus?.exhibitedAt && formatDate(productShopStatus?.exhibitedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
      errorMessage: productShopStatus?.errorMessage,
      createsRegisterItemJob: 'FALSE',
      createsUpdateItemJob: 'FALSE',
      ngWords: ngWords.map(_ => _.word),
      ...(
        [0, 1].reduce((x, i) => {
          const variationChildItem = (variationChild?.items || [])[i];
          const { dimension, index, value } = variationChildItem || {};
          return {
            ...x,
            [`variationType_${i}`]: variationDimensions[dimension] || dimension,
            [`variationLabel_${i}`]: value,
            [`variationNumber_${i}`]: variationChildItem ? `${i === 1 ? '-' : ''}${index + 1}` : null,
          };
        }, {})
      ),
    };
  });
  const onChangeStatus = async (items, status) => {
    if(!window.confirm(`${updatesStatusForAll ? '全店舗' : shop.name}での出品ステータスを更新します。よろしいですか？`)) return;

    try {
      await serial((updatesStatusForAll ? shops : [shop]), async (shop) => {
        const [existingProductShopStatuses] = await Promise.all([
          Promise.all(items.map(_ => getDocumentData(db.collection('productShopStatuses').doc([_.id, expander.id, shop.id].join('__'))))),
          new Promise(_ => setTimeout(_, items.length * 10)),
        ]);
        const existingProductShopStatusesByProductId = keyBy(existingProductShopStatuses, 'productId');
        await batch(db, items, async (batch, item) => {
          batch.set(db.collection('productShopStatuses').doc([item.id, expander.id, shop.id].join('__')), {
            ...(
              existingProductShopStatusesByProductId[item.id] == null && {
                tenantId: item.tenantId,
                productId: item.id,
                expanderId: expander.id,
                shopId: shop.id,
                exhibitedAt: null,
                title: item.amazonProduct?.AttributeSets?.ItemAttributes?.Title ?? '',
                ngWords: (item.ngWords ?? []).map(_ => _.word),
                errorMessage: '',
                createdAt: new Date(),
              }
            ),
            status,
            updatedAt: new Date(),
          }, { merge: true });
          if(status === 'exhibiting') {
            batch.update(item.ref, { shouldUpdateStock: true, });
          }
        }, 250);
      });
      updateScreenVersion();
      updateCountsVersion();
      setSelectedItemIds([]);
      toast.success(`ステータスを更新しました`);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const processRow = async (batch, row, i) => {
    const { tenantId, id, status, createsRegisterItemJob, createsUpdateItemJob, } = row;

    const productShopStatusId = [id, expander.id, shop.id].join('__');
    const [existingItem] = await Promise.all([
      getDocumentData(db.collection('productShopStatuses').doc(productShopStatusId)),
      new Promise(_ => setTimeout(_, 10)),
    ]);
    batch.set(db.collection('productShopStatuses').doc(productShopStatusId), {
      ...(
        existingItem == null && {
          tenantId,
          productId: id,
          expanderId: expander.id,
          shopId,
          exhibitedAt: null,
          errorMessage: '',
          createdAt: new Date(),
        }
      ),
      status: status || 'initial',
      updatedAt: new Date(),
    }, { merge: true });
    status === 'exhibiting' && batch.update(tenantsRef.doc(tenantId).collection('products').doc(id), { shouldUpdateStock: true });
    const create = (type) => {
      const jobId = [expander.id, shop.id, id].join('--');
      batch.set(db.collection('registerItemJobs').doc(jobId), {
        shopId: shop.id,
        expanderId: expander.id,
        tenantId,
        productId: id,
        count: 0,
        status: 'initial',
        type,
        countedAt: addHours(new Date(), -1), // NOTE: 便宜上、1時間前とする
        createdAt: new Date(),
        errorMessage: null,
        errorStack: null,
        screenshotUrl: null,
        processedAt: null,
      });
    };
    if(createsRegisterItemJob === 'TRUE') create('create');
    if(createsUpdateItemJob === 'TRUE') create('update');
  };
  const onClickReadNewItems = async () => {
    if(!window.confirm(`未登録の商品を約10,000件登録します(在庫がないものは除く、${expander.isDirect ? '直販' : '直販以外'}のみ)。よろしいですか？`)) return;

    const baseProducts = await getCollectionData(db.collectionGroup('products').where('salesDirect', '==', !!expander.isDirect).where('createdAt', '>=', shop.lastProductCreatedAt || new Date(1900, 0, 1)).limit(10000));
    console.log(baseProducts);
    await readNewItems(baseProducts, (batch, baseProducts) => {
      batch.update(shop.ref, { lastProductCreatedAt: last(baseProducts).createdAt, });
    });
    baseProducts.length > 0 && await shop.ref.update({ lastProductCreatedAt: last(baseProducts).createdAt, });
  }
  const onClickReadTenantItems = async () => {
    const tenant = tenantsById[queryParams.tenantIdToRead];
    if(!window.confirm(`${tenant?.name}の未登録の商品を登録します(在庫がないものは除く)。よろしいですか？`)) return;

    const baseProducts = await getAllCollectionDataByChunk(tenant.ref.collection('products').orderBy('createdAt'));
    await readNewItems(baseProducts);
  }
  const readNewItems = async (baseProducts, beforeCommit = _ => _) => {
    try {
      await serial(chunk(baseProducts, 50), async (baseProducts) => {
        const childProductsRefs = uniqBy(
          baseProducts
            .map(_ => [_, _.ref.parent.parent.id, _.amazonProduct?.Relationships?.VariationParent?.Identifiers?.MarketplaceASIN?.ASIN])
            .filter(_ => _[2]),
          _ => _.slice(1).join('-')
        ).map(_ => _[0].ref.parent.parent.collection('products').where('amazonProduct.Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN', '==', _[2]));
        const childProducts = (await Promise.all(childProductsRefs.map(_ => getCollectionData(_)))).flat();
        const uniqProducts = uniqBy([...baseProducts, ...childProducts], 'id');
        const batch = db.batch();
        const uniqProductsWithStatusId = uniqProducts
          .filter(_ => _.quantity > 0)
          .map(_ => ({ ..._, statusId: [_.id, expander.id, shopId].join('__'), }));
        const existingStatusIds = (await Promise.all(uniqProductsWithStatusId.map(_ => getDocumentData(db.collection('productShopStatuses').doc(_.statusId))))).filter(_ => _).map(_ => _.id);
        const targets = uniqProductsWithStatusId.filter(_ => !existingStatusIds.includes(_.statusId));
        targets.map((target) => {
          const id = target.statusId;
          !existingStatusIds.includes(id) && batch.set(db.collection('productShopStatuses').doc(id), {
            tenantId: target.ref.parent.parent.id,
            productId: target.id,
            expanderId: expander.id,
            shopId,
            status: 'initial',
            exhibitedAt: null,
            title: target.amazonProduct?.AttributeSets?.ItemAttributes?.Title ?? '',
            ngWords: (target.ngWords ?? []).map(_ => _.word),
            errorMessage: '',
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        });
        beforeCommit(batch, baseProducts);
        await batch.commit();
      });
      toast.success('登録しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    updateScreenVersion();
  };
  const isSomeLoading = [isLoadingTenants, isLoadingBaseProducts, isLoadingChildProducts, isLoadingChildProductShopStatuses, isLoadingParentVariations, isLoadingVriations].some(_ => _);
  const onClickRemove = async () => {
    if(!window.confirm('登録削除します。よろしいですか？')) return;

    await serial(chunk(selectedItems, 100), async (items) => {
      await Promise.all(items.map(async (item) => {
        try {
          await db.collection('productShopStatuses').doc([item.id, expander.id, shop.id].join('__')).delete();
        } catch(e) {
          console.error(item, e);
        }
      }));
    });
    toast.success('登録削除しました');
    updateScreenVersion();
  };
  const clearRegisterItemJobs = async () => {
    if(!window.confirm('出品・更新JOBをクリアします。よろしいですか？')) return;

    await (async function clear() {
      const registerItemJobs = await getCollectionData(db.collection('registerItemJobs').where('shopId', '==', shopId).limit(1000));
      if(registerItemJobs.length === 0) return;

      await batch(db, registerItemJobs, (b, _) => b.delete(_.ref));
      await clear();
    })();
    toast.success('クリアしました');
    updateCountsVersion();
  };

  useEffect(() => {
    selectedItemIdsRef.current = selectedItemIds;
  }, [selectedItemIds]);
  useEffect(() => {
    if(selectedItemIds.some(_ => productsById[_] == null)) {
      setSelectedItemIds(selectedItemIds.filter(_ => productsById[_] != null));
    }
  }, [baseProducts, childProducts]);

  return (
    <div className="admin-products container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>商品管理</h4>
        </div>
        <div className="d-flex justify-content-start mb-2">
          <QuerySelector paramName="shopId" options={shopOptions} label={`店舗`} {...{ history, location }} disabled={isSelecting} />
        </div>
        {
          shop != null && (
            <div>
              <div className="d-flex justify-content-start mb-3">
                <div className="card" style={{ opacity: isSearching && 0.4 }}>
                  <div className="card-header">
                    絞り込み
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-1">
                      <QuerySelector width={300} paramName="tenantId" options={tenantOptions} label="テナントで絞込み" {...{ history, location }} disabled={isSelecting} />
                    </div>
                    <div className="d-flex align-items-center flex-wrap mt-2 gap-1">
                      <QuerySelector paramName="status" options={statusOptions} label={`ステータスで絞込み`} {...{ history, location }} disabled={isSelecting} />
                    </div>
                    {
                      false && queryParams.status && (
                        <div className="mt-2 d-flex align-items-center flex-wrap gap-1">
                          <DateSelector paramName="exhibitedAtStartOn" label="出品日(開始)" pickerProps={{ maxDate: new Date() }} />
                          <DateSelector paramName="exhibitedAtEndOn" label="出品日(終了)" invalid={exhibitedAtStartOn > exhibitedAtEndOn} pickerProps={{ maxDate: new Date() }} />
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="card ml-3">
                  <div className="card-header">
                    検索
                  </div>
                  <div className="card-body">
                    <div className="mb-3 d-flex align-items-center">
                      <div className="mt-2 d-flex align-items-center flex-wrap">
                        <QuerySelector paramName="searchType" options={searchTypeOptions} label="検索対象" {...{ history, location }} />
                        <QueryInput className="ml-2" paramName="keyword" label="検索キーワード" {...{ history, location }} disabled={!isSearching} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card ml-3">
                  <div className="card-header">未登録商品の登録</div>
                  <div className="card-body">
                    <div>
                      <div className="text-muted small">{(_ => _ && `${formatDate(_, 'yyyy/MM/dd HH:mm')}の商品まで済`)(shop.lastProductCreatedAt?.toDate())}</div>
                      <ProgressButton process={onClickReadNewItems}>
                        全テナントの未登録商品を登録する
                      </ProgressButton>
                    </div>
                    <div className="mt-4">
                      <QuerySelector width={300} paramName="tenantIdToRead" options={tenantOptions} label="テナント選択" {...{ history, location }} disabled={isSelecting} />
                      <ProgressButton process={onClickReadTenantItems} disabled={isEmpty(queryParams.tenantIdToRead)}>
                        未登録商品を登録する
                      </ProgressButton>
                    </div>
                  </div>
                </div>
                <div className="card ml-3">
                  <div className="card-header text-nowrap">
                    ステータス別件数
                    <Button color="link" size="sm" onClick={updateCountsVersion}>
                      <span className="fas fa-sync" />
                    </Button>
                  </div>
                  <div className="card-body">
                    {
                      entries(productShopStatusCounts || {}).map(([status, { count }]) => {
                        return (
                          <div key={status} className="d-flex justify-content-between">
                            <span>{productStatuses[status]?.label}:</span>
                            <span>{numeral(count).format()}</span>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                <div className="card ml-3">
                  <div className="card-header text-nowrap">
                    出品・更新JOB残数
                    <Button color="link" size="sm" onClick={updateCountsVersion}>
                      <span className="fas fa-sync" />
                    </Button>
                    <ProgressButton color="link" size="sm" process={clearRegisterItemJobs}>
                      <span className="text-danger fas fa-times" />
                    </ProgressButton>
                  </div>
                  <div className="card-body">
                    {
                      entries(registerItemJobCounts || {}).map(([countIndex, { count }]) => {
                        return (
                          <div key={countIndex} className="d-flex justify-content-between">
                            <span>{countIndex}回:</span>
                            <span>{numeral(count).format()}</span>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="mb-3 d-flex align-items-end justify-content-start gap-3">
                <div>
                  <div className="d-flex gap-1">
                    <QuerySelector paramName="countPerPage" options={countPerPageOptions} defaultValue="100" label="表示件数(同親ASINグループ件数)" {...{ history, location }} disabled={isSelecting} selectorProps={{ isClearable: false }} />
                    <QuerySelector paramName="ngWordType" options={ngWordTypeOptions} label="NGワードの有無で絞込み" {...{ history, location }} disabled={isSelecting} />
                  </div>
                </div>
                <div className="d-flex align-items-end gap-1">
                  <ExportButton fileName="products.csv" rows={rowsForExport.bind(null, filteredRowItems.flat(), variationsById)} />
                  <ExportButton label={`条件一致全商品エクスポート${progress != null ? ` (${numeral(progress).format('0.0%')})` : ''}`} fileName="all_products.csv" rows={allRowsForExport} />
                  <ExportButton label="条件一致全商品エクスポート(出品用)" fileName="all_products_for_exhibit.csv" rows={allRowsForExportForExhibit} />
                  <ImportButton beforeStart={_ => window.confirm(`${shop.name}へのインポートを開始します。よろしいですか？`)} onComplete={_ => updateScreenVersion()} processRow={processRow} documentName="product" fields={fieldsForAdminImport({ tenants, shops, })} chunkSize={100} disabled={isSomeLoading} />
                </div>
              </div>
              <div className="d-flex justify-content-between mb-2">
                {
                  isSelecting && (
                    <div className="alert alert-secondary d-flex align-items-end gap-1">
                      <div className="card" style={{ minWidth: 400 }}>
                        <div className="card-header">ステータス変更</div>
                        <div className="card-body">
                          <div className="d-flex gap-1 align-items-end">
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox" checked={updatesStatusForAll} onChange={_ => setUpdatesStatusForAll(_.target.checked)} />
                                全店舗対象
                              </Label>
                            </FormGroup>
                            <div style={{ width: 200, }}>
                              <Select
                                options={statusOptions}
                                onChange={_ => onChangeStatus(selectedSameIdInMallItems, _.value)}
                                className="w-100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <RegisterItemButton type="create" expander={expander} label="出品" products={uniqParentVariationSelectedItems} {...{ shop, tenantsById, parentVariationsById, selectedSameIdInMallItems, updateScreenVersion, updateCountsVersion, }} />
                      <RegisterItemButton type="update" expander={expander} label="更新" products={uniqParentVariationSelectedItems} {...{ shop, tenantsById, parentVariationsById, selectedSameIdInMallItems, updateScreenVersion, updateCountsVersion, }} />
                      {
                        user.dev && (
                          <Fragment>
                            <RegisterItemButton early type="create" expander={expander} label="出品(早)" products={uniqParentVariationSelectedItems} {...{ shop, tenantsById, parentVariationsById, selectedSameIdInMallItems, updateScreenVersion, updateCountsVersion, }} />
                            <RegisterItemButton early type="update" expander={expander} label="更新(早)" products={uniqParentVariationSelectedItems} {...{ shop, tenantsById, parentVariationsById, selectedSameIdInMallItems, updateScreenVersion, updateCountsVersion, }} />
                          </Fragment>
                        )
                      }
                      <ProgressButton color="danger" process={onClickRemove}>
                        登録削除
                      </ProgressButton>
                    </div>
                  )
                }
              </div>
              <div className="mt-2 overflow-scroll position-relative" style={{ zIndex: 0 }}>
                {
                  isSomeLoading ? (
                    <span className="fas fa-spin fa-spinner" />
                  ) : (
                    <div>
                      <table className="table sticky-table table-bordered">
                        <thead className="thead-light text-center">
                          <tr className="text-nowrap">
                            <th>
                              <Input type="checkbox" className="position-relative m-0" checked={difference(filteredRowItems.flat().map(_ => _.id), selectedItemIds).length === 0} onChange={_ => _.target.checked ? setSelectedItemIds(filteredRowItems.flat().map(_ => _.id)) : setSelectedItemIds([])} />
                            </th>
                            <th>
                            </th>
                            <th>画像</th>
                            <th style={{ minWidth: 500 }}>商品名</th>
                            <th>出品可否</th>
                            <th style={{ minWidth: 150 }}>会社名</th>
                            <th>テナントID</th>
                            <th style={{ minWidth: 150 }}>評価情報</th>
                            <th>カテゴリ</th>
                            <th>親ASIN</th>
                            <th>バリエーション情報</th>
                            <th>ASIN</th>
                            <th style={{ minWidth: 100 }}>
                              SKU
                            </th>
                            <th style={{ minWidth: 200 }}>
                              ID
                            </th>
                            <th style={{ minWidth: 200, }}>モール出品ID</th>
                            <th>Amazon価格</th>
                            <th>在庫数</th>
                            <th>卸値</th>
                            <th>予想出品金額</th>
                            <th>
                              更新日時
                            </th>
                            <th>
                              オーナー更新日時
                            </th>
                            <th>
                              登録日時
                            </th>
                            <th style={{ minWidth: 150 }}>
                              ステータス
                            </th>
                            <th style={{ minWidth: 150 }}>
                              出品日時
                            </th>
                            <th style={{ minWidth: 500 }}>
                              自動出品エラー
                            </th>
                            <th style={{ minWidth: 300 }}>
                              メモ
                            </th>
                          </tr>
                        </thead>
                        {
                          filteredRowItems.map((items) => {
                            const key = items.map(_ => _.id).join('-'); 
                            return (
                              <tbody key={key} style={{ border: '3px solid #ccc', }}>
                                {
                                  items.map((item) => {
                                    const { ref, amazonProduct, } = item;
                                    const variationParentAsin = getVariationParentAsin(item);
                                    const tenant = tenantsById[ref.parent.parent.id];
                                    const parentVariation = parentVariationsById[[tenant?.id, variationParentAsin].join('--')];
                                    const variation = variationsById[variationParentAsin];
                                    const isSelected = selectedItemIds.includes(item.id);
                                    return (
                                      <Row key={[ref.parent.parent.id, item.id].join('-')} {...{ user, shops, sameGroupItems: items, item, isSelected, expander, tenant, parentVariation, variation, unselectItemId, selectItemId, }} />
                                    );
                                  })
                                }
                              </tbody>
                            );
                          })
                        }
                      </table>
                      {
                        baseProducts.length === displayCount && (
                          <div className="mt-3">
                            <Button onClick={readMore}>
                              もっと見る
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
});

const Row = memo((props) => {
  const { user, shops, sameGroupItems, item, isSelected, expander, tenant, parentVariation, variation, unselectItemId, selectItemId, } = props;
  const { id, sku, ref, updatedAt, createdAt, netPrice, amazonProduct, amazonProductEvaluationData, quantity, inventorySyncFailedAt, inventorySyncErrorMessages, ngWords = [], statusesData, adminNote, productShopStatus, } = item;
  const { browseClassification } = amazonProduct?.catalogSummaries?.[0] || {};
  const title = get(amazonProduct, 'AttributeSets.ItemAttributes.Title');
  const imageUrl = get(amazonProduct, 'AttributeSets.ItemAttributes.SmallImage.URL');
  const asin = get(amazonProduct, 'Identifiers.MarketplaceASIN.ASIN');
  const amount = get(amazonProduct, 'Offers.Offer.BuyingPrice.ListingPrice.Amount');
  const variationParentAsin = getVariationParentAsin(item);
  const _pricePerVariationStatus = pricePerVariationStatus(parentVariation);
  const variationChild = get(variation, 'variationChildren', []).find(_ => get(_, 'Identifiers.MarketplaceASIN.ASIN') === asin);
  const url = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;

  return (
    <tr>
      <td>
        <Input type="checkbox" className="position-relative m-0" checked={isSelected} onChange={_ => isSelected ? unselectItemId(item.id) : selectItemId(item.id)} />
        {
          user.dev && (
            <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Ftenants~2F${tenant.id}~2Fproducts~2F${item.id}`} target="_blank">
              F
            </a>
          )
        }
      </td>
      <td className="text-nowrap">
        <SyncButton tenant={tenant} sku={sku} />
        <AmazonEvaluateButton className="ml-2" product={item} />
      </td>
      <td>
        <img src={imageUrl} style={{ maxWidth: 80 }} />
      </td>
      <td className="text-break">
        <a href={url} target="_blank">
          <span dangerouslySetInnerHTML={{ __html: highlightText(title, ngWords.map(_ => _.word)) }} />
        </a>
      </td>
      <td>
        {
          ngWords.length > 0 && <span className="badge badge-warning">NGワードあり</span>
        }
      </td>
      <td>
        <div>
          {user.admin ? <Link to={`/admin/tenants/${tenant?.id}`} target="_blank">{tenant?.name}<span className="ml-1 fas fa-external-link-alt" /></Link> : tenant?.name}
          {tenant?.spApiAuthStatus === 'error' && <span className="ml-1 fas fa-exclamation-triangle text-danger large" />}
        </div>
        {
          !isEmpty(get(tenant, 'spId')) && (
            <div>
              <a href={`https://www.amazon.co.jp/s?me=${tenant.spId}&marketplaceID=A1VC38T7YXB528`} target="_blank">
                ストアフロント
                <span className="fas fa-external-link-alt ml-1" />
              </a>
            </div>
          )
        }
      </td>
      <td>
        {tenant && tenant.id}
      </td>
      <td>
        {
          amazonProductEvaluationData != null && (
            <div>
              <div>出品者数: {amazonProductEvaluationData.sellersCount}</div>
              <div>星の数: {numeral(amazonProductEvaluationData.starValue).format('0.0')}</div>
              <div>レビュー件数: {amazonProductEvaluationData.reviewsCount}</div>
            </div>
          )
        }
      </td>
      <td>
        [{browseClassification?.classificationId}] {browseClassification?.displayName}
      </td>
      <td className="text-nowrap">
        {
          variationParentAsin && (
            <div>
              <div>{variationParentAsin}</div>
            </div>
          )
        }
      </td>
      <td>
        {
          variationChild != null && (
            variationChild.items.length > 0 ? (
              <dl>
                {
                  variationChild.items.map(({ dimension, index, value }, i) => {
                    return isString(value) && (
                      <Fragment key={dimension}>
                        <dt className="small text-muted">{variationDimensions[dimension] || dimension}</dt>
                        <dd>[{i === 1 ? '-' : ''}{index + 1}] {value}</dd>
                      </Fragment>
                    );
                  })
                }
              </dl>
            ) : (
              <div className="text-muted small">バリエーション情報を取得しましたが表示すべき情報がありません</div>
            )
          )
        }
      </td>
      <td className="text-nowrap">
        <div>{asin}</div>
        <EditButton label="ASIN履歴" size="sm" processValues={_ => ({ ..._, asins: uniq(_.asins.split(',')), })} itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'ASIN履歴', fields: { asins: { type: 'string', label: 'ASIN履歴', hint: 'カンマ区切り', } } }} />
      </td>
      <td className="text-break">{sku}</td>
      <td className="text-break">{id}</td>
      <td className="text-break">
        {computeIdInMall(item, tenant, parentVariation)}
      </td>
      <td className="text-nowrap text-right">
        {yen(parseInt(amount, 10))}
      </td>
      <td className="text-nowrap text-right">
        {numeral(quantity).format('0,0')}
      </td>
      <td className="text-right">
        {yen(netPrice)}
      </td>
      <td className="text-right">
        {yen(round(netPrice * 1.3))}
      </td>
      <td className="text-nowrap">
        {updatedAt && formatDate(updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td className="text-nowrap">
        {productShopStatus?.updatedAt && formatDate(productShopStatus.updatedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td className="text-nowrap">
        {createdAt && formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td className="text-nowrap">
        {productStatuses[productShopStatus?.status]?.label}
      </td>
      <td>
        {productShopStatus?.exhibitedAt && formatDate(productShopStatus?.exhibitedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td>
        {productShopStatus?.errorMessage}
      </td>
      <td>
        <div className="d-flex justify-content-between">
          <div className="flex-fill" style={{ whiteSpace: 'pre-line', }}>
            {adminNote}
          </div>
          <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '商品メモ', fields: productAdminFields(), }} />
        </div>
      </td>
    </tr>
  );
}, (prevProps, props) => {
  const comparedFields = ['item', 'isSelected', 'tenant', 'parentVariation', 'variation'];
  return isEqual(pick(prevProps, comparedFields), pick(props, comparedFields));
});

function SyncButton (props) {
  const { tenant, sku, ...extraProps } = props;
  const [isSyncing, toggle] = useToggle(false);
  const onClickSync = async () => {
    toggle(true);
    try {
      await fetchParentVariation({ tenantId: tenant.id, sku, });
      toast.success('バリエーション情報を取得しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggle(false);
  };

  return (
    <Button size="sm" onClick={onClickSync} {...extraProps}>
      <span className={classnames('fas fa-sync', { 'fa-spin': isSyncing })} />
      バリエーション情報取得
    </Button>
  );
}

function AmazonEvaluateButton (props) {
  const { tenant, product, ...extraProps } = props;
  const onClick = async () => {
    try {
      await product.ref.update({ requiresToGetProductEvaluationData: true });
      toast.success('評価を開始しました');
    } catch(e) {
      toast.error('失敗しました');
    }
  };

  return (
    <Button size="sm" onClick={onClick} {...extraProps}>
      評価
    </Button>
  );
}

function RegisterItemButton (props) {
  const { early = false, type, updateScreenVersion, updateCountsVersion, selectedSameIdInMallItems, shop, label, expander, products, tenantsById, parentVariationsById, ...extraProps } = props;
  const process = async () => {
    await batch(db, products.filter(_ => tenantsById[_.ref.parent.parent.id]?.spApi), (batch, product) => {
      const variationParentAsin = getVariationParentAsin(product);
      const parentVariation = parentVariationsById[[product.ref.parent.parent.id, variationParentAsin].join('--')];
      const tenant = tenantsById[product.ref.parent.parent.id];
      const id = [expander.id, shop.id, (shop.mall === 'mercari' ? product.id : computeIdInMall(product, tenant, parentVariation))].join('--');
      batch.set(db.collection('registerItemJobs').doc(id), {
        shopId: shop.id,
        expanderId: expander.id,
        tenantId: product.ref.parent.parent.id,
        productId: product.id,
        count: 0,
        status: 'initial',
        type,
        countedAt: addHours(new Date(), early ? -1000000 : -1), // NOTE: 便宜上、1時間前とする
        createdAt: new Date(),
        errorMessage: null,
        errorStack: null,
        screenshotUrl: null,
        processedAt: null,
      });
    });
    await batch(db, selectedSameIdInMallItems.filter(_ => _.productShopStatus), (batch, item) => {
      batch.update(item.productShopStatus.ref, { 
        updatedAt: new Date(),
        status: 'processing',
      });
    });
    updateScreenVersion();
    updateCountsVersion();
    toast.success(`出品JOBを登録しました`);
  };

  return (
    <ProgressButton process={process} {...extraProps}>
      {label}
    </ProgressButton>
  );
}
